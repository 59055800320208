<template>
    <div
        class="vehicl-map"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <div class="map-con">
            <div class="functionalArea" :class="{'isLargeScreen': isLargeScreen}">
                <div class="station-list el_left" v-if="stationList.length>1">
                    <el-select
                        v-model="currentStationId"
                        placeholder="请选择"
                        @change="changeStation"
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div v-if="iconStatus" class="search-car-div el_left">
                    <el-input placeholder="输入查询位置。。。" v-model="keyword"></el-input>
                    <i @click="onSearchResult">搜索</i>
                </div>

                <div v-if="!iconStatus" class="search-car-div el_left">
                    <el-input placeholder="工程名称/工程地址/车号/车牌号" v-model="searchInfo"></el-input>
                    <i @click="getLocation">搜索</i>
                </div>
                <div class="checkout-icon el_left">
                    <el-tooltip
                        v-show="iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索工程与车辆信息"
                        placement="bottom"
                    >
                        <i @click="checkoutIcon" class="el-icon-map-location"></i>
                    </el-tooltip>
                    <el-tooltip
                        v-show="!iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索地理位置信息"
                        placement="bottom"
                    >
                        <i @click="checkoutIcon" class="el-icon-truck"></i>
                    </el-tooltip>
                </div>
                <div class="setting-top-box el_left" @click="setCarTime">
                    <span class="iconfont">&#xe672;</span>
                    <span>设置</span>
                </div>
            </div>
            <!-- 总设置 -->
            <div class="setting-window" v-show="settingWindowStatus">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button type="primary" size="mini" @click="setCarInterval(0)">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatus = false">
                        取消
                    </el-button>
                </div>
            </div>
            <!-- 单独车辆设置 -->
            <div class="setting-window setting-window-two" v-show="settingWindowStatusTwo">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button @click="setCarInterval(0)" type="primary" size="mini">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatusTwo = false">
                        取消
                    </el-button>
                </div>
            </div>
            <!-- 右侧功能面板 -->
            <actionsPanel
                v-if="!isLargeScreen"
                :projects-statistics="projectsStatistics"
                :car-statistics="carStatistics"
                :projects-address="projectsAddress"
                @projectsClick="handleProjectsClick"
                @carClick="handleCarClick"
                :current-station-id="currentStationId"
            ></actionsPanel>
            <div id="mapContainer"></div>
        </div>
    </div>
</template>
<script>
// /* eslint-disable */
import { eventBus } from '@/common/eventBus.ts';
import { mapStyleBrown } from './../../../assets/map/themeJson.js';
import util from '@/utils/util';
import actionsPanel from './actionsPanel';
import GeoUtils from '@/plugins/BMapLibGeoUtils/index';
// import projectAddressWindow from './projectAddressWindow';

export default {
    name: 'baidu-map',
    components: {
        actionsPanel,
        // projectAddressWindow,
    },
    props: {
        isLargeScreen: {
            type: [Boolean],
            required: false, // 是否必传
        },
        pageType: {
            type: [Array, Object, String ],
            required: false, // 是否必传
        },
    },
    data: function () {
        return {
            // 地图对象
            MapObj: {},
            loading: false,
            iconStatus: true,
            settingWindowStatus: false,
            settingWindowStatusTwo: false,
            searchInfo: '',
            keyword: '',
            setWholeTime: 0,
            // 场站
            stationList: [],
            projectsStatistics: {
                all: 0,
                notWork: 0,
                notFinished: 0,
                trans: 0,
            },
            // 工程地址统计
            carStatistics: {
                all: 0,
                notWork: 0,
                trans: 0,
                back: 0,
            },
            // 工地源数据
            projectsAddress: [],
            // 当前场站ID
            currentStationId: '',
            carPosition: {},
            carStatus: {},
            // 工地围栏
            projectEchoPolygon: [],
        };
    },
    // watch: {
    //     pageType(newVal) {
    //         if (newVal && newVal === 'all') {
    //             this.getAllStation();
    //         }
    //     },
    //     carPosition: {
    //         deep: true,
    //         immediate: true,
    //         handler: function (val) {
    //             this.markers.forEach(item => {
    //                 if (item.carName === val.car_license) {
    //                     const obj = this.bd_encrypt(val.longitude, val.latitude);
    //                     item.position = { lng: obj.bd_lng, lat: obj.bd_lat };
    //                     let direction;
    //                     if (val.direction) {
    //                         direction = parseInt(Number(val.direction), 0);
    //                     } else {
    //                         direction = 0;
    //                     }
    //                     item.direction = direction;
    //                     // const parseIcon = this.getIcon(val);
    //                     // item.icon = parseIcon.icon;
    //                     // item.size = parseIcon.size;
    //                     // item.offset = parseIcon.offset;
    //                     let icon = '';
    //                     // const size = { width: 22, height: 38 };
    //                     const res = {
    //                         icon: '',
    //                         size: { width: 22, height: 38 },
    //                         offset: { width: 5, height: 40 },
    //                     };
    //                     const pt = new this.BMap.Point(item.position.lng, item.position.lat);
    //                     const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
    //                     if ([6].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/blue.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/blue.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([7, 73].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/green.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/green.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([77, 8].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/yellow.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/yellow.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/red.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/red.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }

    //                     }
    //                     res.icon = icon;
    //                     item.icon = res.icon;
    //                     item.size = res.size;
    //                     item.offset = res.offset;
    //                 }
    //             });
    //             this.$forceUpdate();
    //         },
    //     },
    //     carStatus: {
    //         deep: true,
    //         immediate: true,
    //         handler: function (val) {
    //             let is_change = false;
    //             this.markers.forEach(item => {
    //                 if (item.carName === val.car_license && item.status !== val.car_status) {
    //                     let car_type = '';
    //                     const car_icon = '';
    //                     item.status = val.car_status;
    //                     let icon = '';
    //                     // const size = { width: 22, height: 38 };
    //                     const res = {
    //                         icon: '',
    //                         size: { width: 22, height: 38 },
    //                         offset: { width: 5, height: 40 },
    //                     };
    //                     const pt = new this.BMap.Point(item.position.lng, item.position.lat);
    //                     const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
    //                     if ([6].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/blue.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/blue.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([7, 73].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/green.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/green.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([77, 8].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/yellow.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/yellow.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }
    //                     } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
    //                         if (isInarr) {
    //                             icon = require('../../../assets/images/productionManagement/red.png');
    //                             res.size = { width: 22, height: 38 };
    //                             res.offset = { width: 5, height: 40 };
    //                         } else {
    //                             icon = require('../../../assets/images/productionManagement/red.gif');
    //                             res.size = { width: 28, height: 45 };
    //                             res.offset = { width: 5, height: 40 };
    //                         }

    //                     }
    //                     res.icon = icon;
    //                     item.icon = res.icon;
    //                     item.size = res.size;
    //                     item.offset = res.offset;
    //                     if ([6].indexOf(item.status) > -1) {
    //                         car_type = 'trans';
    //                     } else if ([7, 73].indexOf(item.status) > -1) {
    //                         car_type = 'onSite';
    //                     } else if ([77, 8].indexOf(item.status) > -1) {
    //                         car_type = 'back';
    //                     } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
    //                         car_type = 'notWork';
    //                     }
    //                     if (car_type !== '' && item.type !== car_type) {
    //                         is_change = true;
    //                         item.icon = car_icon;
    //                         item.type = car_type;
    //                         this.$forceUpdate();
    //                     }
    //                 }
    //             });

    //             if (is_change) {
    //                 this.oldCars = JSON.parse(JSON.stringify(this.markers));
    //                 let notWork = 0;
    //                 let trans = 0;
    //                 let back = 0;
    //                 let onSite = 0;
    //                 this.markers.map(item => {
    //                     if ([6].indexOf(item.status) > -1) {
    //                         trans += 1;
    //                     } else if ([7, 73].indexOf(item.status) > -1) {
    //                         onSite += 1;
    //                     } else if ([77, 8].indexOf(item.status) > -1) {
    //                         back += 1;
    //                     } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
    //                         notWork += 1;
    //                     }
    //                 });
    //                 this.carStatistics.all = notWork + trans + back + onSite;
    //                 this.carStatistics.notWork = notWork;
    //                 this.carStatistics.trans = trans;
    //                 this.carStatistics.back = back;
    //                 this.carStatistics.onSite = onSite;
    //             }
    //         },
    //     },
    // },
    mounted() {
        this.initMap();
        eventBus.$on('Monitoring', data => {
            this.carStatus = JSON.parse(data);
        });
        eventBus.$on('CoordinateMessage', data => {
            this.carPosition = JSON.parse(data);
        });
        // 是否拉取所有场站
        this.allStation = this.$queryString(this.$route.meta.TableCode, 'type');
    },
    methods: {
        initMap() {
            // 创建地图
            /* eslint-disable no-undef */
            this.MapObj = new BMap.Map('mapContainer');
            // const point = new BMap.Point(113.3309751406, 23.1123809784);
            // this.MapObj.centerAndZoom(point, 20); // 创建点坐标
            this.MapObj.enableScrollWheelZoom(true);// 允许鼠标滚动缩放

            if (this.isLargeScreen) {
                // eslint-disable-next-line no-eval
                this.MapObj.setMapStyleV2({ styleJson: eval(mapStyleBrown) });
            }

            // // 初始化地图， 设置中心点坐标和地图级别
            // const marker = new BMap.Marker(point);
            // this.MapObj.addOverlay(marker); // 添加覆盖物
            // marker.setAnimation(BMAP_ANIMATION_BOUNCE); // 跳动的动画
            // // 添加覆盖物文字
            // const label = new BMap.Label('广州塔', {
            //     offset: new BMap.Size(20, -25),
            // });
            // marker.setLabel(label);

            if (this.allStation === 'all') {
                this.getAllStation();
            } else {
                // 拉取用户场站
                this.getStationUser();
            }
        },
        // 切换icon
        checkoutIcon() {
            this.iconStatus = !this.iconStatus;
            this.searchInfo = '';
            if (this.iconStatus) {
                setTimeout(() => {
                    const searchBoxWrapper = document.getElementsByClassName('search-box-wrapper')[0];
                    const searchInput = searchBoxWrapper.getElementsByTagName('input')[0];
                    searchInput.setAttribute('placeholder', '地图位置');
                }, 50);
            }
        },
        // 秒转天
        SecondToDate(msd) {
            let time = msd;
            if (msd) {
                if (msd > 3600) {
                    time = '一小时以上';
                } else if (msd <= 3600 && msd >= 60 ) {
                    time = '大约 ' + parseInt(time / 60.0, 0) + '分钟 以后';
                } else {
                    time = '一分钟以内';
                }
            } else {
                msd = '';
            }
            return time;
        },
        // 设置车辆时间
        setCarTime() {
            this.settingWindowStatus = true;
            this.getCarInterval(0);
        },
        // 点标记
        switchWindow(tab, item, position) {
            this.loading = true;
            this.switchData = item;
            this.windows.visible = false;
            this.windows.position = position;
            const plate_number = item.plate_number || item.carName;
            this.getVehicleNo(plate_number, tab);
        },
        // 工程地址信息弹框
        showPojectAddressInfoWindow(marker) {
            this.$refs.projectAddressInfoWindow.projectAddressInfo(marker);
        },
        // 显示弹窗
        setWinodShow(event) {
            const setting = document.getElementsByClassName('setting-window-two')[0];
            setting.style.left = event.clientX - 200 + 'px';
            setting.style.top = event.clientY - 180 + 'px';
            this.getCarInterval();
            this.settingWindowStatusTwo = true;
        },
        // 搜索
        onSearchResult() {
            const local = new BMap.LocalSearch(this.MapObj, {
                renderOptions: { map: this.MapObj },
            });
            local.search(this.keyword);
        },
        // 加载地图数据
        reLoadData() {
            this.getProjectStatus();
            // this.getLocation();
        },
        // 获取车辆位置配置
        getCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            this.$axios
                .get(`/interfaceApi/logistics/car_manager/get_car_interval?car_license=${setStatus}`)
                .then(res => {
                    this.setWholeTime = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 高德坐标转百度（传入经度、纬度）
        bd_encrypt(gg_lng, gg_lat) {
            const X_PI = Math.PI * 3000.0 / 180.0;
            const x = gg_lng;
            const y = gg_lat;
            const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
            const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
            const bd_lng = z * Math.cos(theta) + 0.0065;
            const bd_lat = z * Math.sin(theta) + 0.006;
            return {
                bd_lat: bd_lat,
                bd_lng: bd_lng,
            };
        },
        // 拉取车辆位置列表
        getLocation() {
            const carType = 0;
            this.$axios
                // eslint-disable-next-line max-len
                .get(`/interfaceApi/production/vehicleinfo/get_location?stationId=${this.currentStationId}&carType=${carType}&keywords=${this.searchInfo}`)
                .then(res => {
                    const markersArr = [];
                    this.markers = [];
                    if (res.length === 1) {
                        // this.mapCenter = { lng: res[0].longitude, lat: res[0].latitude };
                        this.MapObj.centerAndZoom(new BMap.Point(res[0].longitude, res[0].latitude), 12);
                    }
                    this.carStatistics = {
                        all: 0,
                        notWork: 0,
                        trans: 0,
                        back: 0,
                        onSite: 0,
                    };
                    res.forEach((item, index) => {
                        if (item.status !== 9) {
                            let markersObj = {};
                            if (item.longitude && item.latitude) {
                                const parseIcon = this.getIcon(item);
                                const obj = this.bd_encrypt(item.longitude, item.latitude);
                                // 车辆信息
                                markersObj = {
                                    carName: item.plate_number,
                                    plate_small_number: item.plate_small_number,
                                    position: { lng: obj.bd_lng, lat: obj.bd_lat },
                                    numberOffset: [],
                                    icon: parseIcon.icon,
                                    size: parseIcon.size,
                                    offset: parseIcon.offset,
                                    events: {
                                        click: () => {
                                            this.switchWindow(index, item, markersObj.position);
                                        },
                                    },
                                    visible: false,
                                    type: '',
                                    status: item.status,
                                    rotation: item.direction,
                                };
                                if ([6].indexOf(item.status) > -1) {
                                    markersObj.type = 'trans';
                                    this.carStatistics.trans += 1;
                                } else if ([7, 73].indexOf(item.status) > -1) {
                                    markersObj.type = 'onSite';
                                    this.carStatistics.onSite += 1;
                                } else if ([77, 8].indexOf(item.status) > -1) {
                                    markersObj.type = 'back';
                                    this.carStatistics.back += 1;
                                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                                    markersObj.type = 'notWork';
                                    this.carStatistics.notWork += 1;
                                }
                                markersArr.push(markersObj);
                            }
                        }
                    });
                    this.oldCars = JSON.parse(JSON.stringify(markersArr));
                    this.markers = markersArr;
                    this.carStatistics.all = markersArr.length;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生成车辆icon点标记
        getIcon(item) {
            let icon = '';
            const size = { width: 0, height: 0 };
            const res = {
                icon: '',
                size: size,
                offset: { width: 0, height: 40 },
            };
            const obj = this.bd_encrypt(item.longitude, item.latitude);
            const pt = new this.BMap.Point(obj.bd_lng, obj.bd_lat);
            const isInarr = GeoUtils.isPointInPolygon(pt, this.stationEchoPolygon[0], this.BMap);
            if ([6].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/blue.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/blue.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([7, 73].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/green.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/green.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([77, 8].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/yellow.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/yellow.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }
            } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                if (isInarr) {
                    icon = require('../../../assets/images/productionManagement/red.png');
                    res.size = { width: 22, height: 38 };
                } else {
                    icon = require('../../../assets/images/productionManagement/red.gif');
                    res.size = { width: 28, height: 45 };
                    res.offset = { width: 5, height: 40 };
                }

            }
            res.icon = icon;
            return res;
        },
        // 获取车辆信息
        getVehicleNo(carName) {
            this.$axios
                .get(`/interfaceApi/production/vehicleinfo/get_vehicle_no?plate_number=${carName}`)
                .then(res => {
                    this.carInfo = res;
                    this.windows.visible = true;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error);
                });
        },
        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${setStatus}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.settingWindowStatus = false;
                        this.settingWindowStatusTwo = false;
                        this.setWholeTime = 0;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },
        // 获取当天有任务的工地
        getProjectStatus() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            this.$axios
                .get('/interfaceApi/production/producttask/get_project_status?date=' + currentDate + '&stationCode=' + this.currentStationId)
                .then(res => {
                    if (res) {
                        this.projectsAddress = res;
                        this.addProjectFence(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加所有工地marker和围栏数据
        addProjectFence(res) {
            let all = 0; let notWork = 0; let notFinished = 0; let trans = 0;
            this.fenceData = [];
            this.projectsAddressMarkers = [];
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            // 工地按状态分类
            res.map(item => {
                item.longitude = item.project_longitude;
                item.latitude = item.project_latitude;
                if (item.project_longitude && item.project_latitude) {
                    const markersObj = {
                        position: { lng: item.project_longitude, lat: item.project_latitude },
                        icon: require('../../../assets/images/productionManagement/flag_blue.png'),
                        size: { width: 26, height: 24 },
                        visible: false,
                        status: item.status,
                        type: '',
                        project_code: item.project_code,
                    };
                    if (item.status === 1) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_red.png');
                        markersObj.type = 'notWork';
                        item.fenceType = 'notWork';
                    } else if (item.status === 3) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_green.png');
                        markersObj.type = 'trans';
                        item.fenceType = 'trans';
                    } else {
                        markersObj.type = 'notFinished';
                        item.fenceType = 'notFinished';
                    }
                    this.projectsAddressMarkers.push(markersObj);
                    item.longitude = item.project_longitude;
                    item.latitude = item.project_latitude;
                    this.fenceData.push(item);
                }
                if (item.status === 1) {
                    notWork += 1;
                } else if (item.status === 3) {
                    trans += 1;
                } else {
                    notFinished += 1;
                }
            });
            this.oldProjects = JSON.parse(JSON.stringify(this.projectsAddressMarkers));
            all = res.length;
            this.projectsStatistics.all = all;
            this.projectsStatistics.notWork = notWork;
            this.projectsStatistics.notFinished = notFinished;
            this.projectsStatistics.trans = trans;
            // 所有工地画围栏
            this.drawStationFence(res, 'project');
        },
        // 拉取用户关联场站
        getStationUser() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user?userId=' + Uid)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.currentStationId = res[0].station_id;
                        this.reLoadData();
                        // this.mapCenter = { lng: res[0].longitude, lat: res[0].latitude };
                        this.MapObj.centerAndZoom(new BMap.Point(res[0].longitude, res[0].latitude), 12);
                        this.drawStationFence([res[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 拉取所有场站
        getAllStation() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId + '&branchId=' + BranchId)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.currentStationId = res[0].station_id;
                        this.reLoadData();
                        // this.mapCenter = [res[0].longitude, res[0].latitude];
                        this.MapObj.centerAndZoom(new BMap.Point(res[0].longitude, res[0].latitude), 12);
                        this.drawStationFence([res[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 顶部场站筛选
        changeStation() {
            this.$emit('siloSwitchingStation', this.currentStationId);
            let selectStation = {};
            this.stationList.forEach((item, index) => {
                if (item.station_id === this.currentStationId) {
                    selectStation = item;
                    this.activeStation = index;
                }
            });
            // this.mapCenter = [selectStation.longitude, selectStation.latitude];
            this.MapObj.centerAndZoom(new BMap.Point(selectStation.longitude, selectStation.latitude), 12);
            this.reLoadData();
            // 画当前场站围栏
            if (this.stationEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.stationEchoPolygon);
            }
            this.stationCircles = [];
            this.timer = new Date().getTime();
            this.drawStationFence([selectStation], 'station');
        },
        // 画围栏或一公里圆圈
        drawStationFence(data, type) {
            if (data) {
                data.map(item => {
                    if (item.fence_point) {
                        // const arr = item.fence_point.split('|');
                        // const polygonPath = [];
                        // arr.map(item => {
                        // const lngLat = item.split(',');
                        // const arr = this.bd_encrypt(lngLat[0], lngLat[1]);
                        // const mPoint = new BMap.Point(arr.bd_lng, arr.bd_lat);

                        const mPoint = new BMap.Point(116.67188003186551, 35.45121671139759);
                        // polygonPath.push(obj);
                        this.mapObj.addOverlay(new BMap.Circle(mPoint, 1000, {
                            strokeOpacity: 0.3,
                            fillColor: '#00b0ff',
                            strokeColor: '#80d8ff',
                            fillOpacity: 0.3,
                            strokeWeight: 2,
                        }));
                        // });

                        // const mPoint = new BMap.Point(116.404, 39.915);
                        // map.enableScrollWheelZoom();
                        // map.centerAndZoom(mPoint, 15);
                        // const circle = new BMap.Circle(mPoint, 1000, {
                        //     strokeOpacity: 0.3,
                        //     enableEditing: true,
                        //     fillColor: '#00b0ff',
                        //     strokeColor: '#80d8ff',
                        //     fillOpacity: 0.3,
                        //     strokeWeight: 2,
                        // });
                        // this.mapObj.addOverlay(circle);

                        // // 创建面覆盖物
                        // // eslint-disable-next-line no-undef
                        // const echoPolygon = new this.BMap.Polygon(polygonPath, {
                        //     fillColor: '#00b0ff',
                        //     strokeColor: '#80d8ff',
                        //     fillOpacity: 0.3,
                        //     strokeWeight: 2,
                        // });
                        if (type === 'project') {
                            this.projectEchoPolygon.push(echoPolygon);
                        } else {
                            this.stationEchoPolygon.push(echoPolygon);
                        }
                        // this.MapObj.addOverlay(echoPolygon);
                    } else if (item.longitude && item.latitude) {
                        if (type === 'project') {
                            const arr = this.bd_encrypt(item.longitude, item.latitude);
                            this.projectCircles.push(
                                {
                                    center: { lng: arr.bd_lng, lat: arr.bd_lat },
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: 0.8, // 轮廓线透明度
                                    strokeWeight: 2, // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        } else {
                            const arr = this.bd_encrypt(item.longitude, item.latitude);
                            this.stationCircles.push(
                                {
                                    center: { lng: arr.bd_lng, lat: arr.bd_lat },
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: 0.8, // 轮廓线透明度
                                    strokeWeight: 2, // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        }
                    }
                });
            }
        },
        // 点击右侧工地筛选
        handleProjectsClick(num) {
            // 选中的工地类型
            this.activeProject = num;
            let Markers = [];
            this.projectsAddressMarkers = this.oldProjects;
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.removeOverlay(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            let fenceData = [];
            switch (this.activeProject) {
                case 2:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notwork') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notwork') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 3:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notFinished') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notFinished') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 4:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'trans') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'trans') {
                            fenceData.push(item);
                        }
                    });
                    break;
                default:
                    // 所有工地
                    fenceData = this.fenceData;
                    Markers = this.projectsAddressMarkers;
            }
            // 工地围栏
            this.drawStationFence(fenceData, 'project');
            this.projectsAddressMarkers = Markers;
        },
        // 点击右侧车辆筛选
        handleCarClick(num) {
            this.markers = this.oldCars;
            let data = [];
            // this.markers = [];
            switch (num) {
                case 2:
                    this.oldCars.map(item => {
                        if (item.type === 'trans') {
                            data.push(item);
                        }
                    });
                    break;
                case 3:
                    this.oldCars.map(item => {
                        if (item.type === 'back') {
                            data.push(item);
                        }
                    });
                    break;
                case 4:
                    this.oldCars.map(item => {
                        if (item.type === 'notWork') {
                            data.push(item);
                        }
                    });
                    break;
                case 5:
                    this.oldCars.map(item => {
                        if (item.type === 'onSite') {
                            data.push(item);
                        }
                    });
                    break;
                default:
                    data = this.oldCars;
            }
            this.markers = data;
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('Monitoring');
        eventBus.$off('CoordinateMessage');
    },
};

</script>

<style lang="stylus">
@import "./../css/vehicleLogisticsMonitoringBd.styl";
</style>