const mapStyleBrown = [
    {
        featureType: 'land',
        elementType: 'geometry',
        stylers: {
            color: '#0b1b2bff',
        },
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: {
            color: '#1c3345ff',
        },
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#ffffff66',
            weight: 10,
        },
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'green',
        elementType: 'geometry',
        stylers: {
            color: '#1422151a',
        },
    },
    {
        featureType: 'building',
        elementType: 'geometry.fill',
        stylers: {
            color: '#4a90e21a',
        },
    },
    {
        featureType: 'building',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'manmade',
        elementType: 'geometry',
        stylers: {
            color: '#12223d33',
        },
    },
    {
        featureType: 'manmade',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#ffffff26',
        },
    },
    {
        featureType: 'manmade',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'subwaystation',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'education',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'education',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#ffffff26',
            weight: 10,
        },
    },
    {
        featureType: 'education',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'medical',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'medical',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'scenicspots',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'scenicspots',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'entertainment',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'estate',
        elementType: 'geometry',
        stylers: {
            color: '#4a90e226',
        },
    },
    {
        featureType: 'shopping',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'transportation',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'transportation',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'highway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'highway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'nationalway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'nationalway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'provincialway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'provincialway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'cityhighway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'cityhighway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'arterial',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'arterial',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'tertiaryway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'tertiaryway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'fourlevelway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'fourlevelway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'local',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'local',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'scenicspotsway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'scenicspotsway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'universityway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'universityway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'vacationway',
        elementType: 'geometry.fill',
        stylers: {
            color: '#1b344eff',
        },
    },
    {
        featureType: 'vacationway',
        elementType: 'geometry.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'railway',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'subway',
        elementType: 'geometry',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'highwaysign',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'highwaysign',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'highway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'subwaylabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'subwaylabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'tertiarywaysign',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'tertiarywaysign',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'provincialwaysign',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'provincialwaysign',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'nationalwaysign',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'nationalwaysign',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'highwaysign',
        elementType: 'labels.text.fill',
        stylers: {
            weight: 10,
        },
    },
    {
        featureType: 'nationalwaysign',
        elementType: 'labels.text.fill',
        stylers: {
            weight: 10,
        },
    },
    {
        featureType: 'provincialwaysign',
        elementType: 'labels.text.fill',
        stylers: {
            weight: 10,
        },
    },
    {
        featureType: 'highway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffffa',
        },
    },
    {
        featureType: 'nationalway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'nationalway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'provincialway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'provincialway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'cityhighway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'cityhighway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'arterial',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'arterial',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'arterial',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'tertiaryway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'tertiaryway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'tertiaryway',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'fourlevelway',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'fourlevelway',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'fourlevelway',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'local',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e2ff',
            weight: 10,
        },
    },
    {
        featureType: 'local',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'local',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'airportlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'airportlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'scenicspotslabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'scenicspotslabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'educationlabel',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e240',
        },
    },
    {
        featureType: 'educationlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'educationlabel',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'medicallabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'medicallabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'entertainmentlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'entertainmentlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'estatelabel',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e299',
        },
    },
    {
        featureType: 'estatelabel',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'estatelabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'businesstowerlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'businesstowerlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'companylabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'companylabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'governmentlabel',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e266',
        },
    },
    {
        featureType: 'governmentlabel',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'governmentlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'poilabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'poilabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'restaurantlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'restaurantlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'hotellabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'hotellabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'shoppinglabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'shoppinglabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'carservicelabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'carservicelabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'lifeservicelabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'lifeservicelabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'transportationlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'transportationlabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'financelabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'financelabel',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'continent',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#333333ff',
        },
    },
    {
        featureType: 'continent',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffffff',
        },
    },
    {
        featureType: 'city',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e280',
        },
    },
    {
        featureType: 'city',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff1a',
        },
    },
    {
        featureType: 'city',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'district',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'town',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e280',
        },
    },
    {
        featureType: 'town',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff0',
        },
    },
    {
        featureType: 'village',
        elementType: 'labels.text.fill',
        stylers: {
            color: '#4a90e280',
        },
    },
    {
        featureType: 'village',
        elementType: 'labels.text.stroke',
        stylers: {
            color: '#ffffff00',
        },
    },
    {
        featureType: 'educationlabel',
        elementType: 'labels.text',
        stylers: {
            fontsize: 28,
        },
    },
    {
        featureType: 'governmentlabel',
        elementType: 'labels.text',
        stylers: {
            fontsize: 28,
        },
    },
    {
        featureType: 'roadarrow',
        elementType: 'labels.icon',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'educationlabel',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
    {
        featureType: 'education',
        elementType: 'labels',
        stylers: {
            visibility: 'off',
        },
    },
];

export { mapStyleBrown };
